import { SignupUser } from 'views/auth/signup/user';
import { SignupVerification } from 'views/auth/signup/verify';
import { Signin } from 'views/auth/signin';
import CorsFallback from 'views/cors-fallback';

const Routes = [
  { 
    path: '/signup/verify',
    view: SignupVerification,
    layout: 'auth',
    title: 'Please verify your email address'
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to VIPSRV'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Welcome to VIPSRV'
  },
  {
    path: '/cors-fallback',
    view: CorsFallback,
    layout: 'auth'
  },
]

export default Routes;
