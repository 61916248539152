import {useState, useEffect, useContext} from 'react';
import Axios from 'axios';

import {
  FormHeader, TextInput, NumberInput, EmailInput, URLInput,
  PhoneInput, DateInput, DateTimeInput, PasswordInput, HiddenInput, CardInput, Select, Multiselect, FormLink,
  Switch, FileInput, Fieldset, Button, ViewContext, useNavigate, ClassHelper, Options
} from 'components/lib'

import {CardElement} from '@stripe/react-stripe-js';
import Style from './form.tailwind.js';
import {Autocomplete} from './input/autocomplete.js';

function CreateExperienceForm(props) {
  const saveDraftText = 'Save Draft';
  const editText = 'Save Changes';
  const requestReviewTest = 'Submit For Review';

  // context & state
  const context = useContext(ViewContext);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileStore, setFileStore] = useState([]);
  const [processCreditCard, setProcessCreditCard] = useState(false);
  const navigate = useNavigate();
  let valid = true;
  // inputs map
  const Inputs = {

    text: TextInput,
    textarea: TextInput,
    email: EmailInput,
    number: NumberInput,
    url: URLInput,
    date: DateInput,
    dateTime: DateTimeInput,
    hidden: HiddenInput,
    phone: PhoneInput,
    password: PasswordInput,
    creditcard: CardInput,
    radio: Fieldset,
    select: Select,
    multiselect: Multiselect,
    checkbox: Fieldset,
    selector: Fieldset,
    switch: Switch,
    header: FormHeader,
    link: FormLink,
    file: FileInput,
    autocomplete: Autocomplete

  }

  useEffect(() => {

    // if the form is valid and using
    // live updates, refresh the form
    if (valid && props.updateOnChange) {

      setForm(props.inputs);

    }

    // otherwise, only init if no form set
    else if (!form) {

      let data = {...props.inputs};

      // init credit card
      if (data?.token) {

        data?.plan?.default === 'free' ?
          setProcessCreditCard(false) :
          setProcessCreditCard(true);

      }

      setForm(data);
    }
  }, [props, form, valid]);

  if (!form)
    return false;

  function getBack() {
    context.modal.show({
      title: `Any changes to this Experience will not be saved. Continue back to All Experiences?`,
      cancel: true,
      customButton: {
        color: 'green',
        text: 'Go Back',
        action: () => navigate(`/account/experience`),
      },
    }, () => {
    });
  }
    function update(input, value, valid, idx) {
      let data = {...form}
      // is it a file?
      if (value.length && value[0].name && value[0].type && value[0].size) {

        if (!fileStore[input]?.length) {
          fileStore[input] = [];
        }
        const newFiles = fileStore;
        if (newFiles[input]?.length && value[0].name == newFiles[input]?.[0].name) {
          newFiles[input] = undefined;
        } else {
          newFiles[input] = value;
        }

        data[input].value = newFiles[input];
        data[input].valid = valid;
        setFileStore(newFiles);

      }
      else {
        // update input value & valid state
        if (typeof idx === 'number') {
          data.options.values[idx][input].value = value;
          data.options.values[idx][input].valid = valid;
        } else {
          data[input].value = value;
          data[input].valid = valid;
        }

        // hide credit card input when selecting free plan
        if (props.inputs.token) {
          if (input === 'plan' && value === 'free') {

            setProcessCreditCard(false)

          }
          else if (input === 'plan' && value !== 'free') {

            setProcessCreditCard(true)

          }
        }
      }

      data.date.disabled = !!data.ongoing.value;
      data.options.values.forEach(o => {
        o.price.disabled = !!data.price_upon_request.value;
        o.price.required = !data.price_upon_request.value;
      });

      setForm(data);
      props.updateOnChange &&
        props.onChange({input: input, value: value, valid: valid});

      props.submitOnChange && submit();

    }

    function validate() {

      // loop over each input and check it's valid
      // show error if input is required and value is
      // blank, input validation will be executed on blur

      let errors = [];
      let data = {...form};

      // loop the inputs
      for (let input in data) {

        // validate credit card
        if (input === 'token') {
          if (processCreditCard && data.token.value.error) {

            data.token.valid = false
            errors.push(false);

          }
          else {

            data.token.valid = true;

          }
        }
        else {

          // standard input
          let inp = data[input];
          if (inp.value === undefined && inp.default) {

            data[input].value = inp.default;

          }

          if (inp.required) {
            if (!inp.value || inp.value === 'unselected') {

              inp.valid = false;
              errors.push(false);

            }
          }

          if (inp.valid === false) {

            errors.push(false);

          }
        }
      }

      if (errors.length) {

        // form isn't valid
        valid = false;
        setForm(data);
        return false;

      }
      else {

        // form is valid
        return true;

      }
    }

    async function submit(status) {

      // submit the form
      setLoading(true);
      let data = {...form};


    if (data.photo?.value?.length === 1 && typeof data.photo?.value[0] === 'string' && data.photo.objectKey?.length) {
      data.photo.value = [data.photo.objectKey]
    }


      // create the credit card token
      if (processCreditCard) {

        const cardElement = await props.elements.getElement(CardElement);
        const token = await props.stripe.createToken(cardElement);
        data.token.value = token.token;

      }

      // is the form valid?
      if (!validate()) {

        setLoading(false);
        return false;

      }

      // optimise data for server
      for (let input in form) {
        if (processCreditCard && input === 'token') {

          // procress credit card
          data[input] = form[input].value;

        }
        else if (input !== 'header') {

          // process single input & ignore headers

          if (input === 'options') {
            data[input] = form[input].values.map((optionName) => {
              return Object.keys(optionName).reduce((accum, optionInput) => {
                accum[optionInput] = optionName[optionInput].value
                return accum
              }, {});
            }
              ,);
          } else if (input === 'categories') {
            data[input] = form[input].value.map((category) => {
              if (category?.name) {
                return category.id;
              } return category;
            })
          } else data[input] = form[input].value;
        }
      }

      delete data.header;

      // submit the form or execute callback
      if (!props.url) {

        if (props.callback)
          props.callback(null);

        return false;

      }

      if (status) {
        data.status = status
      }

      const ongoing = data.ongoing
      delete data.ongoing
      if (ongoing) {
        data.date = null
      }

      try {

        let formData = new FormData(), headers = {};
        if (Object.keys(fileStore).length) {
          headers['Content-Type'] = 'multipart/form-data';
          headers['Accept'] = 'application/json';
          for (let key in data) {

            // append files
            if (Array.isArray(data[key]) && data[key][0]?.hasOwnProperty('data')) {
              for (let i = 0;i < data[key].length;i++) {
                formData.append(key, data[key][i].data);

              }
            } else if (key === 'options' || key === 'categories') {
              formData.append(key, JSON.stringify(data[key]));
            }
            else {

              // append text values
              formData.append(key, data[key]);

            }
          }
          data = formData;

        }
        let res = await Axios({
          headers,
          method: props.method,
          url: props.url,
          data: data

        });

        // check for 2-factor payment requirement
        if (res.data.requires_payment_action) {

          const stripeRes =
            await props.stripe.handleCardPayment(res.data.client_secret);

          if (stripeRes.error) {

            setLoading(false);
            context.handleError(stripeRes.error.message);
            return false;

          }
          else {

            // re-send the form
            data.stripe = res.data;
            res = await Axios({

              method: props.method,
              url: props.url,
              data: data

            });
          }
        }

        // finish loading
        setLoading(false);

        // callback?
        if (props.callback)
          props.callback(res);

        // redirect?
        if (props.redirect)
          navigate(props.redirect);

        // success notification
        if (res.data.message)
          context.notification.show(res.data.message, 'success', true);

      }
      catch (err) {

        // handle error
        setLoading(false);
        context.modal.hide(true);

        // show error on input
        if (err.response?.data?.inputError) {

          let data = {...form}
          const input = err.response.data.inputError;
          data[input].valid = false;
          data[input].errorMessage = err.response.data.message;
          valid = false;
          setForm(data);
          return false;

        }
        else {

          // general errors handled by view
          context.handleError(err);

        }
      }
    }

    const getActionButtons = () => {
      if (!props.id) {
        return [{
          color: 'blue',
          action: () => submit('draft'),
          text: saveDraftText
        },
        {
          color: 'green',
          action: () => submit('pending_review'),
          text: requestReviewTest
        }
        ]
      }
      if (props.readonly) {
        return []
      }

      if (props.isUpdatesPendingChild) {
        return [
          {
            color: 'blue',
            action: () => submit(),
            text: editText
          }
        ]
      }

      const buttons = [
        {
          color: 'blue',
          action: () => submit(),
          text: editText
        }
      ]
      if (props.experienceStatus === 'draft') {
        buttons.push({
          color: 'green',
          action: () => submit('pending_review'),
          text: requestReviewTest,
        })
      }
      return buttons;
    };


    const formStyle = ClassHelper(Style, {
      ...props, ...{

        loading: props.loading || loading

      }
    });

    // map the inputs
    let inputsToRender = [];
    Object.keys(form).map(name => {
      // get the values for this input
      const data = form[name];
      data.name = name;
      inputsToRender.push(data);
      return inputsToRender;
    });

    // map the options inputs
    const optionsValuesArr = form.options.values.map((option) => {
      return Object.keys(option).reduce((accum, name) => {
        // get the values for this input
        const data = option[name];
        data.name = name;
        accum.push(data);
        return accum;
      }, []);
    }).sort((a, b) => {
      const aSortOrder = a.find((input) => input.name === 'sort_order');
      const bSortOrder = b.find((input) => input.name === 'sort_order');
      return aSortOrder.value - bSortOrder.value
    });

    // render the form
    return (

      <form
        action={props.action}
        method={props.method}
        className={formStyle}
        encType={fileStore.length && 'multipart/form-data'}
        noValidate>

        {inputsToRender.map(input => {
          if (input.type === null)
            return false;

          if (!input.type)
            input.type = 'text';
          if (input.type === 'options')
            return false;

          const Input = Inputs[input.type];

          return (
            <Input
              key={input.name}
              type={input.type}
              form={props.name}
              label={input.label}
              tip={input.tip}
              className={input.class}
              name={input.name}
              value={input.value}
              required={input.required}
              valid={input.valid}
              min={input.min}
              max={input.max}
              disabled={input.disabled}
              options={input.options}
              default={input.default}
              url={input.url}
              text={input.text}
              title={input.title}
              accept={input.accept}
              description={input.description}
              readonly={input.readonly}
              maxFileSize={input.maxFileSize}
              handleLabel={input.handleLabel}
              placeholder={input.placeholder}
              errorMessage={input.errorMessage}
              onChange={update}
              complexPassword={input.complexPassword}
              metadata={input.metadata}
            />
          );
        })}
        <Options
          optionsData={props.inputs.options}
          optionsValuesArr={optionsValuesArr}
          update={update}
          setForm={setForm}
          priceUponRequest={form.price_upon_request.value}
        />
        {
          getActionButtons().map(({color, action, text, disabled}, i) => (
            <div className="mb-4" key={i}>
              <Button
                key={text}
                color={color}
                text={text}
                className={Style.button}
                action={action}
                fullWidth={true}
                disabled={disabled}
              />
            </div>
          ))
        }
      <div>
        <Button
          color='gray'
          text='Back to All Experiences'
          className={Style.backButton}
          action={() => getBack()}
        />
      </div>
      </form>
    );
  }

  export {CreateExperienceForm}